import { ChevronRightIcon } from "../icons/ChevronRightIcon";

const FeaturedProduct = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 text-center my-5">
          <h2 className="fw-bold text-uppercase">Featured Products</h2>
        </div>
        <div className="col-12 col-md-7 mx-auto">
          <div className="row row-cols-1 row-cols-md-2">
            {[1, 2].map((data, idx) => {
              return (
                <div className="col ">
                  <div className="bg-gray mx-1 p-2">
                    <h3>
                      Neque porro quisquam est qui dolorem ipsum quia dolor sit
                      amet
                    </h3>
                    <span className="text-muted text-decoration-underline">
                      Learn More <ChevronRightIcon />{" "}
                    </span>
                    <div
                      className="mx-auto my-1"
                      style={{ width: "100%", height: "200px", background:"#f1f1f1" }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedProduct;
