import { ChevronRightIcon } from "../icons/ChevronRightIcon";
import { StartHalfIcon } from "../icons/StartHalfIcon";
import { StartIcon } from "../icons/StartIcon";

const Product = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 text-center my-5">
          <h2 className="fw-bold text-uppercase">Deal of the week</h2>
        </div>
        <div className="col-12 col-md-8 mx-auto">
          <div className="row row-cols-1 row-cols-md-5 g-2">
            {[...Array(15).keys()].map((data, idx) => {
              return (
                <div className="col ">
                  <div className="bg-gray mx-1 p-2 text-center">
                    
                    <div
                      className="mx-auto my-1"
                      style={{ width: "100%", height: "100px", background:"#f1f1f1" }}
                    ></div>
                    <span className="text-muted">Prodduct Name</span> <br />
                    <span className="text-primary">
                      <StartIcon />
                      <StartIcon />
                      <StartIcon />
                      <StartIcon />
                      <StartHalfIcon />{" "}
                    </span><br/>
                    <span>$100</span> <br />
                    <button className="btn btn-primary my-2">Add cart</button>
                    
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
