import { CartIcon } from "../icons/CartIcon";
import { HeartIcon } from "../icons/HeartIcon";
import { PhoneIcon } from "../icons/PhoneIcon";
import { SearchIcon } from "../icons/SearchIcon";
import Container from "./Container";

const Footer = () => {
  return (
    <>
      <Container styleClass="bg-black text-white mt-5">
        <div className="row my-3">
          <div className="col-md-3 px-2">
            <span className="fw-bold">About</span>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown
            </p>
          </div>
          <div className="col-md-3 px-2">
            <span className="fw-bold">Get in one touche</span>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been
            </p>
          </div>
          <div className="col-md-3 px-2">
            <span className="fw-bold">Quick Links</span>
            {[...Array(5).keys()].map((data, idx) => {
              return <p>Links {idx + 1}</p>;
            })}
          </div>
          <div className="col-md-3 px-2">
            <span className="fw-bold">Get in Touch</span>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. 
            </p>
            <div class="mb-3">
              <input
                type="email"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Email address"
              />
            </div>
            <button className="btn btn-primary">Subscribe</button>
          </div>
        </div>
      </Container>
      <Container>
        <div className="row row-cols-1 row-cols-md-3">
          <div className="col">
            <span>LOGO AFRICARPARTS</span>
          </div>
          <div className="col">
            <span className="fw-bold">Free shipping : </span>
            <span>On All U.S Orders Over $100</span>
          </div>
          <div className="col">
            <div className="d-flex">
              <div className="ms-auto">
                <span>
                  <PhoneIcon />
                </span>
                <span>+226 xx xx xx xx</span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Footer;
