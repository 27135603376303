import Banier from "../components/Banier"
import FeaturedProduct from "../components/FeaturedProduct"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Offer from "../components/Offer"
import Product from "../components/Product"


const Home = () => {

    return <>
        <Header />
        <Banier />
        <FeaturedProduct />
        <Product />
        <Offer />
        <Footer />
    </>
}

export default Home