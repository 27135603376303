import { CartIcon } from "../icons/CartIcon";
import { HeartIcon } from "../icons/HeartIcon";
import { PhoneIcon } from "../icons/PhoneIcon";
import { SearchIcon } from "../icons/SearchIcon";
import Container from "./Container";

const Header = () => {
  return (
    <>
      <Container>
        <div className="row row-cols-1 row-cols-md-3">
          <div className="col">
            <span>Help | Order Status</span>
          </div>
          <div className="col">
            <span className="fw-bold">Free shipping : </span>
            <span>On All U.S Orders Over $100</span>
          </div>
          <div className="col">
            <div className="d-flex">
              <div className="ms-auto">
                <span>
                  <PhoneIcon />
                </span>
                <span>+226 xx xx xx xx</span>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container styleClass="bg-gray mt-1">
        <div className="d-flex align-items-center justify-content-between my-3">
          <div className="col-md-3 fw-bold">LOGO AFRICARPARTS</div>
          <div className="col-md-6">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search by make Model Year Product Type, Part Number..."
              />
              <span className="input-group-text bg-primary" id="basic-addon2">
                <SearchIcon />
              </span>
            </div>
          </div>
          <div className="col-md-3 align-items-center">
            <div className="d-flex justify-content-end">
              <span className="me-3">My Account</span>
              <div className="me-3">
                <div className="dropdown">
                  <span
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Garage
                  </span>

                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="me-3 position-relative">
                <HeartIcon />
                <span className="position-absolute d-flex align-items-center justify-content-center counte">
                  15
                </span>
              </div>
              <div className="me- position-relative">
                <CartIcon />
                <span className="position-absolute d-flex align-items-center justify-content-center counte">
                  5
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Header;
