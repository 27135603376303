import { Carousel } from "./Carousel";
import Container from "./Container";
const Banier = () => {
  const array = [
    "Exterior",
    "Interior",
    "Performance",
    "Wheels & Tires",
    "Body Parts",
    "Repair parts",
    "Electronics",
    "Tools & Garage",
  ];
  return (
    <>
      <Container styleClass={"my-1"}>
        <div className="d-flex justify-content-center">
          {array.map((data, idx) => {
            return <div className="me-4">{data}</div>;
          })}
        </div>
      </Container>
      <div className="row position-relative">
        <div className="col-12 p-0">
          <Carousel />
        </div>
        <div className="col-12 position-absolute">
          <div className="row">
            <div className="col-12 col-md-9 mx-auto">
              <div className="input-group mt-5">
                <div>
                  <h1 className="fw-bold text-uppercase">
                    Select Your Vehicule
                  </h1>
                  <span>Customize, Modify, Upgrade, Repair, Replace</span>
                </div>
              </div>
              <div className="w-60 mt-3">
                {["Year", "Make", "Model"].map((data, idx) => {
                  return (
                    <div className="input-group mb-3" key={data}>
                      <span className="input-group-text" id="basic-addon1">
                        {idx + 1}
                      </span>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>{data}</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  );
                })}
                <div className="input-group">
                  <button className="btn btn-primary w-100">Shop Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row bg-white">
        <div className="col-12 text-center my-5">
          <h2 className="fw-bold text-uppercase">Browse by categories</h2>
        </div>
        <div className="col-12 col-md-10 mx-auto">
            <div className="row row-cols-12 row-cols-md-12 g-2">
            {
                array.map((data,idx) =>{

                    return <div className="col shadow1 mx-2 text-center bg-gray py-1">
                        <div className=" rounded-5 mx-auto mb-1" style={{width:"100px", height:"100px"}}>

                        </div>
                        {data}
                    </div>
                })
            }
            </div>
        </div>
      </div>
    </>
  );
};

export default Banier;
